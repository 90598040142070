<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">
        Users ({{ users.length }})
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row v-if="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search email"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>
      <v-btn class="ml-5" icon @click="showSearch = !showSearch">
        <img src="@/assets/icons/search.svg" alt height="17.7px" />
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="mr-1" icon @click="exportUsers" v-on="on">
            <v-icon>{{ icons.export }}</v-icon>
          </v-btn>
        </template>
        <span>Export to Excel</span>
      </v-tooltip>
    </v-app-bar>
    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-row class="d-flex flex-row">
        <v-spacer></v-spacer>
        <v-col md="4">
          <v-autocomplete
            :items="userTypes"
            dense
            label="Filter User Type"
            clearable
            outlined
            v-model="filter.userType"
            @change="search()"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <simple-table
        :loading="loading"
        :headers="tableHeaders"
        :collection="users"
        :sort="sort"
        @headerClicked="sortUsers"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import SimpleTable from '@/components/SimpleTable'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'

export default {
  name: 'UsersPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    SimpleTable,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      userTypes: ['Tradie', 'Customer'],
      filter: {
        search: '',
        userType: '',
      },

      sort: 'created_at',

      icons: {
        sort: mdiUnfoldMoreHorizontal,
        settings: mdiDotsVertical,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
      },
    }
  },

  created() {
    this.clearUsers()
    this.fetchUsers(1)
  },

  watch: {
    sort: function (newSort, oldSort) {
      this.clearUsers()
      this.fetchUsers()
    },
  },

  computed: {
    ...mapState({
      users: (state) => state.user.list,
      listMeta: (state) => state.user.listMeta,
    }),

    ...mapGetters('user', ['userCount']),

    paginationLength() {
      return this.listMeta.last_page | 0
    },

    tableHeaders() {
      return [
        { property: 'email', width: '40%' },
        { property: 'user_type', sortable: false },
        { property: 'created_at', name: 'Added date' },
      ]
    },
  },

  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      exportToExcel: 'user/exportToExcel',
    }),

    ...mapMutations({
      clearUsers: 'user/clearUserList',
    }),

    sortUsers(property) {
      this.sort = (this.sort.startsWith('-') ? '' : '-') + property
    },

    pageChanged(page) {
      this.fetchUsers(page)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearUsers()
      this.fetchUsers()
    }, 600),

    async fetchUsers(page = 1) {
      if (this.loading) return

      const params = {
        page,
        sort: this.sort,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      if (this.filter.userType) {
        params.type = this.filter.userType
      }

      this.loading = true
      await this.getUsers(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    async exportUsers() {
      this.loading = true
      await this.exportToExcel()
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `users${dayjs().format('YYYYMMDD')}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
  },
}
</script>
